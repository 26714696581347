import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { Suspense, lazy, useState } from 'react';

// Import  icons
import { Matterport } from '@assets';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { useSelector } from 'react-redux';
// Import  Components
import MatterportDialog from '@components/Common/Matterport';
import Skeleton from '@components/Common/Skeleton';
import Svg from '@components/Common/Svg';
import Amenity from '@components/Property/Amenity';
import { detailContent } from '@utils/customText';
import useAnalytics from '@components/Common/Analytics';
const SliderWithGLightbox = lazy(() => import('@components/Property/Images'));
const PropertyName = lazy(() => import('@components/Property/Name'));
const OfferStrip = lazy(() => import('@components/Property/OfferStrip'));
const Price = lazy(() => import('@components/Property/Price'));

const PropetyItem = (props) => {
  const { trackEvent } = useAnalytics();
  const [openMatterport, setOpenMatterport] = useState(false);
  const [matterportKey, setMatterportKey] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(-1); // Track index of expanded top amenity list
  const { data } = props;
  const { utmCampaign, utmSource } = useSelector((state) => state.glob);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleOpenatterport = (virtual_tour_url) => {
    setOpenMatterport(true);
    setMatterportKey(virtual_tour_url);
  };

  const toggleTopAmenities = (e, index) => {
    e.stopPropagation();
    setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index)); // Toggle expanded index
  };

  const metrics = (item) => {
    return item.map((itemlist, index) => (
      <>
        <span key={`matrics${index}`}>
          {itemlist.value + ' ' + itemlist.name}
        </span>
        <Divider orientation='vertical' variant='middle' flexItem />
      </>
    ));
  };

  const topAmenities = (insides, index) => {
    return insides.map((itemlist, idx) => (
      <span key={`map${idx}`}>
        <Amenity data={itemlist} className={'el-homeAmenity'} />
      </span>
    ));
  };

  const filterValues = localStorage.getItem('filterValues');

  const detaiPageRedirection = (propertyId, name, city, slug) => {
    const formattedCityName = city.replace(/ /g, '-').toLowerCase();
    const updatedName = name.replace(/[\s-]+/g, '-').toLowerCase();
    const detailPageUrl = slug
      ? `/villas-in-${formattedCityName}/${slug}`
      : `/villas-in-${formattedCityName}?propertyId=${propertyId}`;

    // property card click track event
    trackEvent('Property Card Clicked', {
      property_id: propertyId,
      property_name: name,
      city: city,
      redirect_on: detailPageUrl,
      utmCampaign: utmCampaign,
      utmSource: utmSource,
    });

    window.open(detailPageUrl, isMobile ? '_self' : '_blank');
  };

  return (
    <>
      {data.map((item, index) => (
        <Grid
          className='el-listing-container'
          key={`property${index}`}
          item
          xs={12}
          sm={4}
          md={4}
        >
          {/* Add a key prop */}
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            className='service-item'
          >
            {/* media List */}
            <Suspense
              fallback={
                <div className='transOff'>
                  <Skeleton
                    component='div'
                    variant='text'
                    height={isMobile ? 220 : 300}
                    width={'100%'}
                  />
                </div>
              }
            >
              <div className='relative'>
                {/* Images */}
                <SliderWithGLightbox data={item.Images} id={item.id} />

                {/* Matterport chip */}
                {item?.virtual_tour_url && (
                  <Chip
                    label='3D Tour'
                    size='small'
                    onClick={() => handleOpenatterport(item?.virtual_tour_url)}
                    className='EL-ListingMatterportChip'
                    variant='filled'
                    onDelete={() => handleOpenatterport(item?.virtual_tour_url)}
                    deleteIcon={<Svg src={Matterport} alt='Matterport' />}
                  />
                )}
                {/* Matterport chip end */}
              </div>
            </Suspense>
            {/* media List end*/}

            <CardContent>
              <div
                onClick={() =>
                  detaiPageRedirection(
                    item?.id,
                    item?.name,
                    item?.Address?.city,
                    item?.slug
                  )
                }
              >
                {/* Property Name */}
                <Suspense
                  fallback={
                    <div>
                      <Skeleton
                        component='div'
                        variant='text'
                        height={isMobile ? 30 : 53}
                        width={'100%'}
                      />
                    </div>
                  }
                >
                  <PropertyName
                    className={'propertyName'}
                    address={item?.Address}
                    name={item?.name}
                    rating={item?.rating}
                    page='home'
                  />
                </Suspense>
                {/* Property Name end */}
                <Typography
                  variant='body2'
                  className='amenitiesCardMain'
                  component='div'
                >
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={0}
                    columns={16}
                  >
                    {/* Metrics */}
                    <Grid item sm={16} xs={16}>
                      {item?.Metrics && (
                        <Card
                          className='EL-metrics'
                          elevation={0}
                          variant='elevation'
                        >
                          {' '}
                          {metrics(item?.Metrics)}
                        </Card>
                      )}
                    </Grid>
                    {/* Metrics end*/}
                    {/* TopAmenities start*/}
                    {/* <div className='EL-topAmenitiesList'>
                      {topAmenities(item?.TopAmenities, index)}
                    </div> */}
                    {/* {isMobile && expandedIndex === index && (
                      <div className='EL-topAmenitiesList'>
                        {topAmenities(item?.TopAmenities, index)}
                      </div>
                    )} */}
                    {/* TopAmenities end*/}
                    {/* offer strip */}

                    {/* Property Price */}
                    <Grid className='el-priceGrid-main' item sm={16} xs={16}>
                      {item?.offers?.length > 0 && (
                        <Suspense
                          fallback={
                            <Skeleton
                              component='div'
                              variant='text'
                              height={isMobile ? 42 : 42}
                              width={'100%'}
                            />
                          }
                        >
                          <OfferStrip offers={item?.offers} />
                        </Suspense>
                      )}
                      <div className='el-priceGrid'>
                        <Suspense
                          fallback={
                            <Skeleton
                              component='div'
                              variant='text'
                              height={isMobile ? 70 : 70}
                              width={140}
                            />
                          }
                        >
                          <Price
                            priceLabel={
                              filterValues
                                ? item?.per_night_price === undefined &&
                                  detailContent.startingFrom
                                : detailContent.startingFrom
                            }
                            data={
                              filterValues
                                ? item?.per_night_price ?? item?.price_amount
                                : item?.price_amount
                            }
                            className={'el-priceBox'}
                          />
                        </Suspense>
                        <Button
                          disableRipple
                          color='secondary'
                          variant='text'
                          // onClick={(e) => toggleTopAmenities(e, index)}
                          className='less-info-button' // Add this class name
                        >
                          View Details
                        </Button>
                        {/* {isMobile && (
                        <Button
                          disableRipple
                          endIcon={
                            expandedIndex === index ? (
                              <ArrowDropUpRoundedIcon />
                            ) : (
                              <ArrowDropDownRoundedIcon />
                            )
                          }
                          color='secondary'
                          variant='text'
                          onClick={(e) => toggleTopAmenities(e, index)}
                          className='less-info-button' // Add this class name
                        >
                          {expandedIndex === index ? 'Less info' : 'More info'}
                        </Button>
                      )} */}
                      </div>
                    </Grid>
                    {/* Property Price end*/}
                  </Grid>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {/* Render MatterportDialog */}
      {openMatterport && (
        <MatterportDialog
          matterportKey={matterportKey}
          open={openMatterport}
          setOpen={setOpenMatterport}
        />
      )}
    </>
  );
};

export default PropetyItem;
