import {
  FETCHING_BOOKING_DETAILS_CONTENT_STATUS,
  FETCHING_BOOKING_DETAILS_STATUS,
  FETCHING_BOOKING_LISTING_STATUS,
  FETCHING_REVIEW_BOOKING_STATUS,
  GETTING_BOOKING_DETAILS,
  GETTING_BOOKING_DETAILS_CONTENT_DATA,
  GETTING_BOOKING_LISTING_DATA,
  GETTING_REVIEW_BOOKING_DATA,
  GETTING_USER_ID,
  GET_CONFIRMATION_DETAILS,
  LOADING,
  GETTING_BOOKING_DETAILS_ERROR_STATUS,
  FETCHING_PARTIAL_PAYMENT_TOGGLE,
  fetchBookingDetails,
  fetchBookingDetailsContentStatus,
  fetchBookingDetailsStatus,
  fetchingBookingListing,
  fetchingReviewBookingStatus,
  getBookingDetails,
  getBookingDetailsContentData,
  getConfimationDetails,
  gettingBookingListing,
  gettingReviewBookingData,
  gettingUserIdData,
  gettingBookingDetailsError,
  fetchingPartialPaymentToggle,
} from '@actions/bookingsAction';

import axios from 'axios';
import { gettingUpdatedPricingRes } from '../actions/propertiesAction';

import { instance } from '../instance/axios';
import { errorImage, loadingImage } from './globalReducers';
import { handleErrorResponse } from '@utils/common';
import {
  couponApplied,
  removeCoupon,
  couponResponse,
  customCouponApplied,
  customDoubleDipCouponApplied,
} from '@reducers/couponReducer';
import { HollowCouponApplied } from './couponReducer';

//---------------------INITIAL STATE-------------------

const initialState = {
  loading: false,
  fetchingBookingDetailsStatus: false,
  fetchingBookingDetailsRes: {},
  fetchingUserIdRes: {},
  confirmationDetails: {},
  fetchingBookingDetails: false,
  gettingBookingDetails: {},
  fetchingBookingDetailsContentStatus: false,
  gettingBookingDetailsContentData: {},
  fetchBookingListingStatus: false,
  getBookingListing: [],
  bookingDetailErrorStatus: {},
  isPartialPayment: true,
};

// Utility function to dynamically load script
const loadScript = async (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      reject(new Error('Failed to load script'));
    };
    document.body.appendChild(script);
  });
};

export const loggedIn = (data) => {
  return async (dispatch) => {
    dispatch(fetchingLoggedIn(true));
    dispatch(fetchingResendOtp(true));
    instance({
      method: 'POST',
      url: 'gateways/guest/class/authentication/functions/generateOtp',
      data: data,
    })
      .then((response) => {
        dispatch(fetchingLoggedIn(false));
        dispatch(gettingLoggedInData(response?.data));
        dispatch(isOtpModal(true));
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);
      });
  };
};

// demo api
export const fetchBookingDetail = (id) => {
  return async (dispatch) => {
    dispatch(loadingImage(true));
    instance({
      method: 'GET',
      url: `gateways/visitor/api/properties/${id}`,
    })
      .then((response) => {
        const data = response.data;
        dispatch(fetchBookingDetails(data));
        dispatch(loadingImage(false));
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        dispatch(errorImage(true, error?.message));
        // Add error handling here if needed
      });
  };
};

export const getReviewBookingDetails = (id) => {
  return async (dispatch) => {
    dispatch(loadingImage(true));
    instance({
      method: 'GET',
      url: `gateways/customer/api/cart-bookings/${id}`,
    })
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data;
          if (response?.data) {
            dispatch(fetchingReviewBookingStatus(true));
            dispatch(gettingReviewBookingData(data));
            dispatch(gettingUpdatedPricingRes(data));

            const isSitewideCoupon =
              data?.Order?.Coupons.length > 0
                ? data?.Order?.Coupons.some(
                    (coupon) =>
                      coupon.Offer.is_custom === false &&
                      coupon.OrderCoupon.Coupon.is_double_dip === false &&
                      coupon.Offer.hollow_offer === false
                  )
                : false;

            const isHollowCoupon =
              data?.Order?.Coupons.length > 0
                ? data?.Order?.Coupons.some(
                    (coupon) =>
                      coupon.Offer.is_custom === false &&
                      coupon.OrderCoupon.Coupon.is_double_dip === false &&
                      coupon.Offer.hollow_offer === true
                  )
                : false;

            const isCustomCoupon =
              data?.Order?.Coupons.length > 0
                ? data?.Order?.Coupons.some(
                    (coupon) =>
                      coupon.Offer.is_custom === true &&
                      coupon.OrderCoupon.Coupon.is_double_dip === false
                  )
                : false;

            const isCustomDoubledipCoupon =
              data?.Order?.Coupons.length > 0
                ? data?.Order?.Coupons.some(
                    (coupon) =>
                      coupon.Offer.is_custom === true &&
                      coupon.OrderCoupon.Coupon.is_double_dip === true
                  )
                : false;

            dispatch(HollowCouponApplied(isHollowCoupon));
            dispatch(couponApplied(isSitewideCoupon));
            dispatch(customCouponApplied(isCustomCoupon));
            dispatch(customDoubleDipCouponApplied(isCustomDoubledipCoupon));

            dispatch(loadingImage(false));
          }
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        dispatch(errorImage(true, error?.message));

        // Add error handling here if needed
      });
  };
};

export const updateReviewBookingDetails = (data) => {
  return async (dispatch) => {
    dispatch(loadingImage(true));

    const { bookingId, ...requestData } = data;
    instance({
      method: 'PUT',
      url: `gateways/customer/class/cartBooking/functions/updateOrderBooking?cart_booking_id=${bookingId}`,
      data: requestData,
    })
      .then((response) => {
        if (response?.status === 200) {
          dispatch(loadingImage(false));
          dispatch(getReviewBookingDetails(bookingId));
          const responseData = response.data;
          dispatch(couponResponse(responseData));
          //dispatch(fetchingReviewBookingStatus(true));
          // dispatch(gettingReviewBookingData(responseData));
          // dispatch(bookingStatus(true));
          // dispatch(
          //   gettingBookingRes(
          //     responseData ? responseData?.bookingRequest?.id : ''
          //   )
          // );
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        dispatch(errorImage(true, error?.message));
        console.error('Error updating booking details:', error);
      });
  };
};

// create user
export const createBookingRequest = (data) => {
  const {
    bookingId,
    property_id,
    adults,
    children,
    checkin_on,
    checkout_on,
    property_payment_term_id,
    ...requestData
  } = data;

  // Create an object with booking-related data
  const bookingData = {
    bookingId,
    property_payment_term_id,
  };

  // Create an object with the remaining request data
  const requestDataObject = { ...requestData };

  return async (dispatch) => {
    dispatch(loadingImage(true));

    try {
      const response = await instance({
        method: 'POST',
        url: 'gateways/customer/api/users',
        data: requestDataObject,
      });

      if (
        (response?.status === 201 || response?.status === 200) &&
        response?.data?.status !== 409
      ) {
        dispatch(loadingImage(false));
        const responseData = await response.data;
        const guest_user_id = await response?.data?.id;
        dispatch(gettingUserIdData(responseData));
        dispatch(payNow({ ...bookingData, guest_user_id }));
      } else {
        dispatch(loadingImage(false));

        const guest_user_id = await response?.data?.response?.user?.id;
        //dispatch(gettingUserIdData(responseData));
        dispatch(payNow({ ...bookingData, guest_user_id }));
      }
    } catch (error) {
      dispatch(loadingImage(false));
      dispatch(errorImage(true, error?.message));
      console.error('Error creating booking request:', error);
    }
  };
};

export const payNow = (data) => {
  const { bookingId, property_payment_term_id, ...payNowData } = data;
  return async (dispatch) => {
    try {
      const response = await instance({
        method: 'POST',
        //--------------------ISTAMOJO OLD URL----------------------------
        //url: `gateways/customer/api/cart-bookings/${bookingId}/pay-now?property_payment_term_id=${property_payment_term_id}`,

        //--------------------CASHFREE NEW URL---------------------------
        url: `gateways/customer/class/cartBooking/functions/checkout?cart_booking_id=${bookingId}`,
        data: payNowData,
      });

      if (response?.status === 201) {
        dispatch(loadingImage(false));
        const responseData = response?.data;
        const sessionId =
          responseData?.Transaction?.payment_gateway_request_code;

        const original_order_id =
          responseData?.Transaction?.payment_gateway_code;

        if (sessionId) {
          if (process.env.PG_CASHFREE == 'true') {
            initializeCashfreeSDK().then(() => {
              dispatch(doPayment(sessionId));
            });
          } else {
            dispatch(doPayment(sessionId, original_order_id));
          }
        }
      }
    } catch (error) {
      dispatch(loadingImage(false));
      dispatch(errorImage(true, error?.message));
      console.error('Error updating booking details:', error);
    }
  };
};

export const doPayment = (sessionId, original_order_id) => {
  return async (dispatch) => {
    try {
      if (process.env.PG_CASHFREE == 'true') {
        if (!cashfree) {
          throw new Error('Cashfree SDK not initialized');
        }
        let checkoutOptions = {
          paymentSessionId: sessionId,
          redirectTarget: '_self',
        };
        cashfree.checkout(checkoutOptions);
      } else {
        await payNowWithRazorPay(sessionId, original_order_id);
      }
    } catch (error) {
      console.error('Error in doPayment:', error);
    }
  };
};

export const payNowWithRazorPay = async (sessionId, original_order_id) => {
  const isScriptLoaded = await loadScript(
    'https://checkout.razorpay.com/v1/checkout.js'
  );
  if (!isScriptLoaded) {
    throw new Error('Razorpay SDK failed to load. Are you online?');
  }

  const options = {
    key: process.env.RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
    order_id: sessionId,
    name: 'ELIVAAS',
    image:
      'https://www.elivaas.com/images/logoblack.1e9767d0fc14065fdde5ca3df3d2e8d0.svg',
    callback_url: `${process.env.APP_GATEWAY_URL}/gateways/customer/class/transaction/functions/updateTransaction?order_id=${original_order_id}&payment_gateway_code=${sessionId}`,
    theme: {
      color: '#3399cc',
    },
  };
  var rzp1 = new Razorpay(options);
  rzp1.open();
};

let cashfree = null;

export const initializeCashfreeSDK = async () => {
  try {
    if (!cashfree) {
      const cashfreeModule = await import('@cashfreepayments/cashfree-js');
      const load = cashfreeModule.load;
      cashfree = await load({ mode: process.env.MODE });
    }
    return cashfree;
  } catch (error) {
    console.error('Error initializing Cashfree SDK:', error);
    throw error;
  }
};

export const getConfirmationDetails = (paymentGatewayCode) => {
  return async (dispatch) => {
    dispatch(loadingImage(true));
    instance({
      method: 'GET',
      //--------------------ISTAMOJO OLD URL----------------------------
      // url: `gateways/customer/api/transactions/paymentComplete?payment_request_id=${paymentRequestId}`,
      //--------------------CASHFREE NEW URL---------------------------
      url: `gateways/customer/class/transaction/function/checkTransactionStatus?payment_gateway_code=${paymentGatewayCode}`,
    })
      .then(async (response) => {
        if (response?.status === 200) {
          const responseData = await response?.data;
          dispatch(loadingImage(false));
          dispatch(getConfimationDetails(responseData));
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        dispatch(errorImage(true, error?.message));
      });
  };
};

export const bookingDetails = (id) => {
  return async (dispatch) => {
    dispatch(loadingImage(true));
    dispatch(fetchBookingDetailsStatus(true));
    instance({
      method: 'GET',
      url: `gateways/guest/class/booking/functions/getBooking?id=${id}`,
    })
      .then(async (response) => {
        if (response?.status === 200) {
          const responseData = await response?.data;
          dispatch(loadingImage(false));
          dispatch(fetchBookingDetailsStatus(false));

          dispatch(getBookingDetails(responseData));
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        dispatch(gettingBookingDetailsError(error));
        handleErrorResponse(error, dispatch);
      });
  };
};

export const bookingDetailsContent = (id) => {
  return async (dispatch) => {
    dispatch(loadingImage(true));
    dispatch(fetchBookingDetailsContentStatus(true));
    instance({
      method: 'GET',
      url: 'gateways/visitor/api/namespaces/guest/webPages/booking-detail',
    })
      .then(async (response) => {
        if (response?.status === 200) {
          const responseData = await response?.data;
          dispatch(loadingImage(false));
          dispatch(fetchBookingDetailsContentStatus(false));

          dispatch(getBookingDetailsContentData(responseData));
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        handleErrorResponse(error, dispatch);
      });
  };
};

export const getBookingListingDetails = () => {
  return (dispatch) => {
    dispatch(fetchingBookingListing(true));
    instance({
      method: 'GET',
      url: 'gateways/guest/class/booking/functions/getMyBookings',
    })
      .then((response) => {
        const data = response.data;
        dispatch(fetchingBookingListing(false));
        dispatch(gettingBookingListing(data));
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);

        // Add error handling here if needed
      });
  };
};

export const isPartialPaymentToggle = (status) => {
  return (dispatch) => {
    dispatch(fetchingPartialPaymentToggle(status));
  };
};

//--------------------REDUCER-------------------------
const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCHING_REVIEW_BOOKING_STATUS:
      return {
        ...state,
        fetchingBookingDetailsStatus: action.payload,
      };
    case GETTING_REVIEW_BOOKING_DATA:
      return {
        ...state,
        fetchingBookingDetailsRes: action.payload,
      };
    case GETTING_USER_ID:
      return {
        ...state,
        fetchingUserIdRes: action.payload,
      };
    case GET_CONFIRMATION_DETAILS:
      return {
        ...state,
        confirmationDetails: action.payload,
      };
    case FETCHING_BOOKING_DETAILS_STATUS:
      return {
        ...state,
        fetchingBookingDetails: action.payload,
      };
    case GETTING_BOOKING_DETAILS:
      return {
        ...state,
        gettingBookingDetails: action.payload,
      };
    case FETCHING_BOOKING_DETAILS_CONTENT_STATUS:
      return {
        ...state,
        fetchingBookingDetailsContentStatus: action.payload,
      };
    case GETTING_BOOKING_DETAILS_CONTENT_DATA:
      return {
        ...state,
        gettingBookingDetailsContentData: action.payload,
      };
    case FETCHING_BOOKING_LISTING_STATUS:
      return {
        ...state,
        fetchBookingListingStatus: action.payload,
      };
    case GETTING_BOOKING_LISTING_DATA:
      return {
        ...state,
        getBookingListing: action.payload,
      };
    case GETTING_BOOKING_DETAILS_ERROR_STATUS:
      return {
        ...state,
        bookingDetailErrorStatus: action.payload,
      };
    case FETCHING_PARTIAL_PAYMENT_TOGGLE:
      return {
        ...state,
        isPartialPayment: action.payload,
      };

    default:
      return state;
  }
};

export default bookingReducer;
